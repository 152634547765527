.Header {
	background-color: #2c2c2c;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 120px;
	align-items: center;
	padding: 8px 0px 8px 0px;
	justify-content: center;
}

.HeaderContainer {
	width: 1120px;
	height: 100%;
	background-color: #2c2c2c;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Title {
	background-color: #2c2c2c;
	color: white;
}

.Item {
	font-size: 20px;
	color: white;
	margin-left: 40px;
	cursor: pointer;
	transition: color 0.2s;
	user-select: none;
	font-weight: 200;
	text-transform: uppercase;
	transition: opacity 0.1s;
	text-decoration: none;
}

.Item:hover {
	color: #61a974;
}

.Item:active {
	color: #61a974;
	opacity: 0.8;
}

.DropdownButton {
	color: #61a974;
	/* background-color: khaki; */
	margin-right: -16px;
	margin-left: 5px;
}

.Logo {
	height: 104px;
	cursor: pointer;
}

.RightContainer {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.DropdownContent {
	display: none;
	position: absolute;
	background-color: #2c2c2c;
	min-width: 168px;
	z-index: 1;
	border-radius: 5px;
}

.Dropdown {
	position: relative;
	display: inline-block;
}

.DropdownContent a {
	color: white;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.DropdownContent a:hover {
	background-color: #383838;
	color: #3e8e41;
	border-radius: 5px;
}

.Dropdown:hover .DropdownContent {
	display: block;
}

.Dropdown:hover .Item {
	color: #3e8e41;
}

.Searchbar {
	background-color: transparent;
	width: 160px;
	height: 40px;
	display: flex;
	border: 3px solid white;
	border-radius: 30px;
	align-items: center;
	transition: background-color 0.3s;
	-webkit-transition: width 0.4s ease-in-out;
	width: 0.4s ease-in-out;
	justify-content: center;
	padding: 0px 24px 0px 24px;
}

.Searchbar:focus-within {
	background-color: white;
	width: 240px;
}

.Searchbar:focus-within .Input::placeholder {
	color: grey;
}

.Input {
	width: 100%;
	height: 100%;
	box-shadow: none;
	background-color: transparent;
	border: none;
	color: Black;
	font-size: 16px;
	vertical-align: center;
	outline: none;
	-webkit-appearance: none;
}

.Input::placeholder {
	color: grey;
}

.Moon {
	height: 40px;
	margin-left: 20px;
	cursor: pointer;
}

.Moon:hover {
	color: indigo;
	filter: brightness(0.5);
}