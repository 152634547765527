.RecentPost {
    position: relative;
    float: left;
}
.RecentPostImg {
    cursor: pointer;
    background-color: grey;
    display: flex;
    margin-right: 16px;
    width: 267px;
    height: 150px;
    border: none;
    border-radius: 5px;
    margin-top: 16px;
}
.RecentPostImg:hover {
    transform: scale(1.016);
    transition: all 0.1s;
}
.RecentPostTitle {
    cursor: pointer;
    font-size: 12px;
    word-wrap: break-word;
    display: flex;
    width: 266px;
    height: 110px;
    overflow: hidden;
    line-height: 24px;
    margin-top: -4px;
}
.RecentPostTitle > h2 {
    text-align: left;
    margin-left: 8px;
}
.RecentPostTitle:hover {
    color: #61a974;
    transition: color 0.3s ease;
}
.RecentPostTitle:active {
    color: #61a974;
    opacity: 0.8;
}