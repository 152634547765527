.PostPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.PostTitleContainer {
    position: relative;
    display: flex;
    /* background-color: tomato;
    height: 360px; */
}


.PostTitle {
    display: flex;
    flex-direction: column;
    width: 1120px;
    text-align: center;
    align-self: flex-end;
    margin-top: 26px;
    font-size: 20px;
    /* background-color: khaki; */
}

.PostContent {
    display: flex;
    text-align: left;
    /* background-color: rgb(255, 251, 0); */
    height: 1000px;
    width: 1120px;
    margin-top: 16px;
    line-height: 28px;
    font-size: 18px;
}

.PostText {
    display: flex;
    text-align: justify;
    flex-direction: column;
    height: 1000px;
    width: 860px;
    align-self: center;
    margin-left: 20px;
    line-height: 28px;
    font-size: 18px;
}

.SideInfo {
    display: flex;
    flex-direction: column;
    /* background-color: lightcoral; */
    height: 1000px;
    width: 240px;
    margin-top: 16px;
    line-height: 28px;
    font-size: 18px;
}

.SideDefault {
    color: gray;
    margin-bottom: -16px;
    margin-top: -4px;
}

.PostTags {
    color: #61a974;
    cursor: pointer;
    -moz-user-select: none;
    font-size: 16px;
}

.PostDate {
    color: #61a974;
    -moz-user-select: none;
    font-size: 16px;
}
.PostCover {
    background-color: grey;
    display: flex;
    width: 100%;
    height: px;
    border: none;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
}