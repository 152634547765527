.ThemeIcon {
    color: white;
    border: none;
    transition: color 0.3s ease;
    cursor: pointer;
    user-select: none;
}
.ThemeIcon:hover {
    color: #61a974;
    border: none;
}
.DarkModeContainer {
    margin-left: 16px;
}
body.light-mode {
	background-color: #fff;
	color: #333;
	transition: background-color 0.3s ease;
}
body.dark-mode {
	background-color: #1a1919;
    color: white;
    transition: background-color 0.3s ease;
}