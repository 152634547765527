.Feet {
    background-color: #2c2c2c;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 160px;
    padding: 8px 0px 8px 0px;
    justify-content: center;
    position: absolute;
    margin-top: 40px;
}

.FeetContainer {
    width: 1120px;
    height: 100%;
    background-color: #2c2c2c;
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    place-content: bottom;
}
.FeetItem {
    text-align: left;
    color: white;
    cursor: pointer;
    width: fit-content;
    color: #808080;
}

li {
    margin-top: 10px;
    list-style-type: none;
}
.FeetItem:hover {
    color: #61a974;
}

.FeetItem>a {
    text-decoration: none;
}

.FeetIcon {
    padding-right: 8px;
    justify-items: center;
}

.Rights {
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
    color: white;
    margin-bottom: -4px;
    color: grey;
    user-select: none;
}

a {
    color: inherit;
}