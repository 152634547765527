.CommentSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    /* background-color: hotpink; */
    width: 1120px;
    color: gray;
    margin-top: 20px;
}

.AddComment {
    /* background-color: tomato; */
    display: flex;
    flex-direction: column;
    width: 270px;
    -webkit-transition: width 0.4s, height 0.4s, margin-bottom 0.4s ease-in-out;
    width: 0.4s ease-in-out;
    margin-bottom: 12px;
}
.AddComment:focus-within {
    width: 100%;
    height: 100%;
    /* margin-bottom: 36px; */
}

.CommentInput {
    background-color: transparent;
    font-size: 14px;
    outline: none;
    width: 220px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 20px;
    transition: background-color 0.3s;
    -webkit-transition: width 0.4s, height 0.4s, background-color 0.4s ease-in-out;
    width: 0.4s ease-in-out;
    padding: 14px 24px;
    resize: none;
    /* margin-bottom: 20px; */
    font-family: 'Roboto', sans-serif;
}

.CommentInput:focus-within {
    vertical-align: top;
    background-color: white;
    width: 100%;
    height: 100px;
}

.PostComment {
    background-color: #61a974;
    border: none;
    border-radius: 20px;
    width: 100px;
    margin-top: 8px;
}

.CommentsTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CommentsIconPen {
    margin-left: 12px;
}

.CommentArea {
    display: flex;
    text-align: left;
    align-items: center;
    border-radius: 20px;
    border: solid lightgray 1px;
    margin-bottom: 10px;
}

.AuthorIcon {
    height: 50px;
    border: none;
    margin-left: 16px;
    border-radius: 50px;
}

.CommentAuthor {
    color: rgb(74, 124, 85);
    font-size: 16px;
    font-weight: 500;
}

.CommentText {
    margin-left: 16px;
    /* background-color: indianred; */
    margin-right: 16px;
}

.Comment {
    font-size: 16px;
    margin-top: -4PX;
}
textarea.light-mode {
	background-color: #fff;
	color: rgb(255, 0, 0);
	transition: background-color 0.3s ease;
}
textarea.dark-mode {
	background-color: #1a1919;
    color: rgb(8, 136, 255);
    transition: background-color 0.3s ease;
}
