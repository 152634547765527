.PublishedBy{
    color: grey;
    user-select: none;
}
.AuthorInfo {
    display: flex;
    flex-direction: column;
    /* background-color: lightcoral; */
}
.AuthorImg {
    width: 36px;
    cursor: pointer;
    height: 36px;
    border: none;
    border-radius: 28px;
    align-self: center;
    margin-right: 8px;
}
.AuthorID{
    cursor: pointer;
    color: #61a974;
    user-select: none;
}
.AuthorID:hover{
    text-decoration: underline;
}
.IconAndName {
    margin-top: -16px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}